import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import 'assets/stylesheets/application.scss';

import Footer from './Footer';
import Modal from './Modal';
import Navbar from './Navbar';

const Layout = ({ children, className, path, showFooter = true }) => {
  const [welcomeDismissed, setWelcomeDismissed] = useState(true);

  useEffect(() => {
    const userDismissedWelcome = localStorage.getItem('welcomeDismissed');

    if (!userDismissedWelcome) {
      setWelcomeDismissed(false);
    }
  }, [setWelcomeDismissed]);

  useEffect(() => {
    if (welcomeDismissed) {
      localStorage.setItem('welcomeDismissed', true);
    }
  }, [welcomeDismissed]);

  return (
    <div className={`wrapper ${className || ''}`}>
      <Navbar />
      {children}
      {showFooter && <Footer />}
      {!welcomeDismissed ? (
        <Modal
          className="welcome-message"
          isOpen={welcomeDismissed}
          toggleModal={() => setWelcomeDismissed(!welcomeDismissed)}
          header={<h2>Welcome to the LandScan Population Data Explorer!</h2>}
        >
          <p>
            The premier human geography foundation population datasets -
            LandScan Global, LandScan High-Definition (HD), and LandScan USA -
            are now being offered to the public without restrictions.
          </p>
          <p>
            These datasets are intended to aid in emergency preparedness,
            readiness, response, and recovery missions; risk assessments; site
            suitability studies; and a full range of other applications that
            depend on accurate population data.
          </p>
          <p>
            Begin your data exploration by selecting options from the product
            selector in the lower left-hand corner of the viewer - To download,
            select the download option and access the data.
          </p>
          <p>
            These datasets are offered under the Creative Commons Attribution
            4.0 International License. Users are free to use, copy, distribute,
            transmit, and adapt the data for commercial and non-commercial
            purposes, without restriction, as long as clear attribution of the
            source is provided. For more information, please refer to the{' '}
            <Link
              to="https://creativecommons.org/licenses/by/4.0/"
              target="_blank"
            >
              CC BY 4.0
            </Link>{' '}
            documentation.
          </p>
        </Modal>
      ) : null}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  className: PropTypes.string,
  showFooter: PropTypes.bool
};

export default Layout;
