import { useState } from 'react';
import axios from 'axios';

// If we encounter a problem discovering the IP log this value
const defaultIP = '';

export default function useIP () {
  const [ip, setIP] = useState();

  async function getIP () {
    // Ad blockers can prevent determining the IP address using
    // geolocation-db.com
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      setIP(res.data?.IPv4 || defaultIP);
    } catch (e) {
      console.error(
        'ERROR while getting IP address from geolocation-db.com',
        e
      );
      setIP(defaultIP);
    }
    return ip;
  }

  return {
    ip,
    getIP
  };
}
